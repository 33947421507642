import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FiCalendar, FiClock, FiDollarSign, FiFile } from "react-icons/fi";
import { axiosClient } from "../components/axios/axios";
import Table from "../components/Table";
import ViewButton from "../components/Buttons/ViewButton";
import Cookies from "js-cookie";
import DOMPurify from "dompurify";

function Vacancy() {
  const param = useParams();
  const [singleVacancy, setSingleVacancy] = useState([]);
  const [allApplicents, setAllApplicents] = useState([]);
  const [jobDescription, setJobDescription] = useState();
  const [jobSpecification, setJobSpecification] = useState();
  const [positionOverview, setpositionOverview] = useState();
  const [benefitsAndPerks, setBenefitsAndPerks] = useState();
  const [keyResponsibilities, setkeyResponsibilities] = useState();
  const [keyPerformanceIndicators, setkeyPerformanceIndicators] = useState();
  const [requiredSkillsAndExperience, setrequiredSkillsAndExperience] = useState();
  const token = Cookies.get("token");
  const contentfetched = useRef(false);

  const singleProduct = async () => {
    try {
      let response = await axiosClient.get(`/vacancies/${param.id}`);
      if (response.status === 200) {

        console.log(response.data.job_specification);

        const sanitizedPositionOverview = DOMPurify.sanitize(
          response.data.positionOverview
        );

        const sanitizeDescription = DOMPurify.sanitize(
          response.data.job_description
        );

        const sanitizeSpecification = DOMPurify.sanitize(
          response.data.job_specification
        );

        const sanitizedRequiredSkillsAndExperience = DOMPurify.sanitize(
          response.data.requiredSkillsAndExperience
        );

        const sanitizedKeyPerformanceIndicators = DOMPurify.sanitize(
          response.data.keyPerformanceIndicators
        );

        const sanitizedKeyResponsibilities = DOMPurify.sanitize(
          response.data.keyResponsibilities
        );

        const sanitizedBenefitsAndPerks = DOMPurify.sanitize(
          response.data.benefitsAndPerks
        )

        setJobSpecification(sanitizeSpecification);
        setJobDescription(sanitizeDescription);
        setpositionOverview(sanitizedPositionOverview);
        setrequiredSkillsAndExperience(sanitizedRequiredSkillsAndExperience);
        setkeyPerformanceIndicators(sanitizedKeyPerformanceIndicators);
        setkeyResponsibilities(sanitizedKeyResponsibilities);
        setBenefitsAndPerks(sanitizedBenefitsAndPerks);

        setSingleVacancy(response.data);

      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllApplicants = async () => {
    try {
      let response = await axiosClient.get(
        `/candidate-applications/${param.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        setAllApplicents(response.data.candidate_applications);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (contentfetched.current) return;
    singleProduct();
    if (token) getAllApplicants();

    return () => {
      contentfetched.current = true;
    };
  }, []);
  const tableData = allApplicents.map((applicant, index) => {
    return {
      ...applicant,
      sn: index + 1,
      fullName: applicant.candidateId.fullName,
      email: applicant.candidateId.email,
      contactNumber: applicant.candidateId.contactNumber,

      action: (
        <div className="flex gap-2">
          <ViewButton text={"View"} link={`candidates/${applicant.id}`} />
        </div>
      ),
    };
  });
  const columns = [
    { Header: "SN", accessor: "sn" },
    { Header: "Name", accessor: "fullName" },
    { Header: "Email", accessor: "email" },
    { Header: "Contact Number", accessor: "contactNumber" },
    { Header: "Actions", accessor: "action" },
  ];

  return (
    <main className="max-w-screen-xl mx-auto">
      <hgroup className="flex flex-col lg:flex-row justify-between gap-5 py-10 p-5">
        <div className="grid gap-2 lg:gap-3">
          <h1 className="text-5xl font-bold text-neutral-800">
            {singleVacancy.title}
          </h1>
          <Link
            to={`https://infinitydigitalagency.com.np/`}
            target="_blank"
            className=" p-1 px-3 lg:p-2 lg:px-4  bg-amber-300 lg:text-lg font-medium  w-fit rounded-full"
          >
            {singleVacancy.company_name}
          </Link>
        </div>
        <Link
          to={`/vacancies/${singleVacancy.slug}/application-form`}
          className="w-fit h-fit bg-neutral-800 text-neutral-300 rounded p-4 px-5 hover:bg-amber-400 hover:text-neutral-800 duration-300"
        >
          Apply Now
        </Link>
      </hgroup>
      <div className="border-l">
        <section className="space-y-4 p-5 py-10 ">
          <h2 className="text-2xl font-bold text-neutral-800 border-b pb-3">
            Highlights
          </h2>
          <div className="flex flex-wrap  gap-1 md:gap-2 lg:gap-4">
            <div className="border flex justify-between items-center gap-1 lg:gap-4 group  lg:p-5 duration-300 rounded-md">
              <div className="text-5xl p-4 pr-0 rounded-full text-neutral-400 ">
                <FiClock />
              </div>
              <div className="flex flex-col w-full  pr-4 ">
                <p className=" text-neutral-800 text-xl  font-semibold">
                  {singleVacancy.Experience}
                </p>
                <h2 className="">Experience</h2>
              </div>
            </div>
            <div className="border flex justify-between items-center gap-4 group  lg:p-5 duration-300 rounded-md">
              <div className="text-5xl p-4 pr-0 rounded-full text-neutral-400 ">
                <FiFile />
              </div>
              <div className="flex flex-col w-full pr-4 ">
                <p className="text-neutral-800 text-xl  font-semibold">
                  {singleVacancy.job_type}
                </p>
                <h2 className="text-neutral-800 ">Job Type</h2>
              </div>
            </div>
            <div className="border flex justify-between items-center gap-4 group  lg:p-5 duration-300 rounded-md">
              <div className="text-5xl p-4 pr-0 rounded-full text-neutral-400 ">
                <FiCalendar />
              </div>
              <div className="flex flex-col w-full pr-4 ">
                <p className=" text-neutral-800 text-xl font-semibold">
                  {singleVacancy.application_valid_till}
                </p>
                <h2 className="text-neutral-800 ">Application Deadline</h2>
              </div>
            </div>
          </div>
        </section>
        {/**<section className="py-10 space-y-4 p-5">
          <h2 className="text-2xl font-bold text-neutral-800 border-b pb-3">
            Job Specification
          </h2>

          <div
            className="rich-text-style"
            dangerouslySetInnerHTML={{ __html: jobSpecification }}
          />
        </section>
        <section className="py-10 space-y-4 p-5">
          <h2 className="text-2xl font-bold text-neutral-800 border-b pb-3">
            Job Description
          </h2>
          <div
            className="rich-text-style"
            dangerouslySetInnerHTML={{ __html: jobDescription }}
          />
        </section> **/}
        <section className="py-10 space-y-4 p-5">
          <h2 className="text-2xl font-bold text-neutral-800 border-b pb-3">
            Position Overview
          </h2>

          <div
            className="rich-text-style"
            dangerouslySetInnerHTML={{ __html: positionOverview }}
          />
        </section>

        <section className="py-10 space-y-4 p-5">
          <h2 className="text-2xl font-bold text-neutral-800 border-b pb-3">
            Key Responsibilities
          </h2>

          <div
            className="rich-text-style"
            dangerouslySetInnerHTML={{ __html: keyResponsibilities }}
          />
        </section>

        <section className="py-10 space-y-4 p-5">
          <h2 className="text-2xl font-bold text-neutral-800 border-b pb-3">
            Required Skills and Experience
          </h2>

          <div
            className="rich-text-style"
            dangerouslySetInnerHTML={{ __html: requiredSkillsAndExperience }}
          />
        </section>

        <section className="py-10 space-y-4 p-5">
          <h2 className="text-2xl font-bold text-neutral-800 border-b pb-3">
            Key Performance Indicators
          </h2>

          <div
            className="rich-text-style"
            dangerouslySetInnerHTML={{ __html: keyPerformanceIndicators }}
          />
        </section>

        <section className="py-10 space-y-4 p-5">
          <h2 className="text-2xl font-bold text-neutral-800 border-b pb-3">
            Benefits and Perks
          </h2>

          <div
            className="rich-text-style"
            dangerouslySetInnerHTML={{ __html: benefitsAndPerks }}
          />
        </section>

        

      </div>
      <div className="py-10 p-5">
        <Link
          to={`/vacancies/${singleVacancy.slug}/application-form`}
          className="w-fit bg-neutral-800 text-neutral-300 rounded p-4 px-5 hover:bg-amber-400 hover:text-neutral-800 duration-300"
        >
          Apply Now
        </Link>
      </div>

      {token && <Table columnsData={columns} dataValue={tableData} />}
    </main>
  );
}

export default Vacancy;
