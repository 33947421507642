const handleChange = (setFormData) => (e) => {
  const { name, value, type, checked } = e.target;
  const fieldValue = type === "checkbox" ? checked : value;
  setFormData((prev) => ({
    ...prev,
    [name]: fieldValue,
  }));
};

export default handleChange;
