import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosClient } from "../components/axios/axios";
import Cookies from "js-cookie";
import Dashboard from "./Dashboard";
import { toast } from "react-toastify";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [token, setToken] = useState('');
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");

  // const ulid = ulid();
  const navigate = useNavigate();

  // redux

  const handleSubmit = (event) => {
    event.preventDefault();
    setEmailError("");
    setPasswordError("");
    setLoginError("");
    let canSubmit = true;

    // Email validation
    if (!email.trim()) {
      setEmailError(() => "Email is required");
      canSubmit = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError(() => "Email is invalid");
      canSubmit = false;
    }
    // Password validation
    if (!password.trim()) {
      setPasswordError(() => "Password is required");
      canSubmit = false;
    } else if (password.length < 6) {
      setPasswordError(() => "Password must be at least 6 characters");
      canSubmit = false;
    }

    if (canSubmit) {
      console.log(canSubmit);
      handleAuthentication();
    }
  };
  const handleAuthentication = async () => {
    try {
      const loginStatus = await axiosClient.post("/auth/local", {
        identifier: email,
        password: password,
      });

      if (loginStatus.status === 200) {
        const token = loginStatus.data.jwt;
        Cookies.set("token", token, { expires: 7 });
        toast.success("Login Successful !");
        navigate("/dashboard");
      } else {
        navigate("/login");
        toast.error("Invalid credentials");
      }
    } catch (error) {
      toast.error("Error during authentication");
    }
  };
  // const handleAuthentication = async () => {
  //   try {
  //     const response = await fetch(`${BASEURL}/accounts/authenticate`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ identifier: email, password }),
  //     });

  //     const data = await response.json();

  //     // Check if authentication was successful
  //     if (response.ok) {
  //       const loginData = {
  //         id: data.id,
  //         firstName: data.firstName,
  //         lastName: data.lastName,
  //         email: data.email,
  //         role: data.role,
  //         token: data.jwtToken,
  //       };
  //       if (loginData.role === "Admin") {
  //         navigate("/dashboard");
  //       } else {
  //         navigate("/dashboard/orders");
  //       }
  //     } else {
  //       // Authentication failed
  //       setLoginError(data.message || "Authentication failed");
  //     }
  //   } catch (error) {
  //     setLoginError("Error during authentication");
  //   }
  // };
  console.log(Cookies.get("token"));
  return (
    <main className="py-20" onSubmit={handleSubmit}>
      <form action="" className="max-w-screen-sm mx-auto">
        <div
          className="rounded-lg border bg-card text-card-foreground shadow-sm"
          data-v0-t="card"
        >
          <div className="flex flex-col space-y-1.5 p-6">
            <h3 className="font-semibold whitespace-nowrap tracking-tight text-2xl">
              Login
            </h3>
            <p className="text-sm text-muted-foreground">
              Enter your email below to login to your account.
            </p>
          </div>
          <div className="p-6 space-y-4">
            <div className="space-y-2">
              <label
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="bg-neutral-100 flex h-10 w-full rounded-md border border-neutral-300 border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="m@example.com"
                required=""
                type="email"
              />
            </div>
            <div className="space-y-2">
              <label
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                htmlFor="password"
              >
                Password
              </label>
              <input
                className="bg-neutral-100 flex h-10 w-full rounded-md border border-neutral-300 border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                id="password"
                required=""
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="flex items-center p-6">
            <button className="bg-neutral-800 text-neutral-300 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 w-full">
              Sign in
            </button>
          </div>
        </div>
      </form>
    </main>
  );
}
