import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import Vacancy from "./pages/Vacancy";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ApplicantForm from "./pages/ApplicantForm";
import Candidate from "./pages/Candidate";
import CandidateApplication from "./pages/CandidateApplication";
import Navbar from "./pages/Layouts/Navbar";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navbar />,
      errorElement: <NotFound />,
      children: [
        {
          path: "/",
          element: <HomePage />,
          errorElement: <NotFound />,
        },
        {
          path: "/vacancies/:id",
          element: <Vacancy />,
        },
        {
          path: "/vacancies/:id/application-form",
          element: <ApplicantForm />,
        },
        {
          path: "/vacancies/:id/candidates/:slug",
          element: <CandidateApplication />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
