import axios from "axios";

const CMS_URL = import.meta.env.VITE_CMS_URL;

export const axiosClient = axios.create({
  baseURL: CMS_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
