import React from "react";
// import { FiLogOut } from "react-icons/fi";
import { Link, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import { FiMail, FiPhone } from "react-icons/fi";

export default function Navbar() {
  const token = Cookies.get("token");
  return (
    <>
      <nav className=" flex justify-center md:justify-start gap-2 max-w-screen-xl mx-auto p-5 ">
        <a href="/" className="w-[150px]">
          <img className="w-full" src="/hris-logo.svg" />
        </a>
        {/* {token && (
          <div className="font-bold cursor-pointer">
            <FiLogOut />
          </div>
        )} */}
      </nav>
      <Outlet />
      <footer className=" grid gap-4 justify-center justify-items-center max-w-screen-xl mx-auto md:p-5 py-8 text-lg border-t">
        <div className="flex flex-col md:flex-row gap-2 items-center md:justify-center border-b pb-4 w-fit">
          <div className="flex flex-col sm:flex-row gap-2 items-center">
            <FiPhone />
            <Link rel="stylesheet" to="tel:9810796743">
              +977-9810796743
            </Link>
          </div>
          <span className="hidden md:block">|</span>
          <div className="flex flex-col sm:flex-row gap-2 items-center">
            <FiMail />
            <Link
              rel="stylesheet"
              to="mailto:info@infinitydevelopmententerprise.com"
              className="text-wrap"
            >
              info@infinitydevelopmententerprise.com
            </Link>
          </div>
        </div>

        <p className="text-center">
          ©2024 Infinity Development Enterprise. All Rights Reserved.
        </p>
      </footer>
    </>
  );
}
