import { FiEye } from "react-icons/fi";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
export default function ViewButton({ text, link }) {
  ViewButton.propTypes = {
    text: PropTypes.text,
    link: PropTypes.text,
  };

  return (
    <Link
      to={link}
      className="rounded bg-neutral-700 text-neutral-50 hover:bg-neutral-800 p-3 flex items-center gap-2"
    >
      <FiEye /> {text}
    </Link>
  );
}
