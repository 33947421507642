import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import {
  NumberInput,
  SelectInput,
  TextInput,
} from "../components/Inputs/Inputs";
import { FiSend } from "react-icons/fi";
import { toast } from "react-toastify";
import { axiosClient } from "../components/axios/axios";
import ReactTagInputComponent from "../components/Inputs/ReactTagInputComponent";
import { ApplicationFormContext } from "../context/ApplicationFormContext";

function ApplicantForm() {
  const param = useParams();
  // console.log("param=", param);
  let CMSURL = import.meta.env.VITE_CMS_URL;
  // console.log(CMSURL);
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;

  const [formData, setFormData] = useState({
    currentEmploymentStatus: "FullTime",
  });
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();
  // console.log("formData", formData);

  const singleProduct = async () => {
    try {
      let response = await axiosClient.get(`/vacancies/${param.id}`);
      if (response.status === 200) {
        console.log("This vacancy:", response.data);
        setFormData((prev) => ({
          ...prev,
          vacancies: response.data.id,
          vacancy_id: response.data.vacancy_id,
          title: response.data.title,
          company_name: response.data.company_name,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    singleProduct();
  }, []);
  const handleSubmit = async (e) => {
    // todo - Form validation is necessary
    e.preventDefault();
    setFormData((prev) => ({
      ...prev,
      applicationDate: formattedDate,
      sendingData: true,
    }));
    const formDataToUpload = new FormData();
    const data = {
      fullName: formData.fullName,
      email: formData.email,
      contactNumber: Number(formData.contactNumber),
      citizenship_number: Number(formData.citizenship_number),
      DOB: formData.DOB,
      currentCompany: formData.currentCompany,
      currentPosition: formData.currentPosition,
      currentEmploymentStatus: formData.currentEmploymentStatus,
      vacancies: formData.vacancies,
      vacancy_id: formData.vacancy_id,
      skill: formData.skills,
    };
    // console.log(data);
    formDataToUpload.append("data", JSON.stringify(data));
    if (formData.resume) {
      formDataToUpload.append("files.resume", formData?.resume);
    }

    if (formData.coverLetter) {
      formDataToUpload.append("files.coverLetter", formData?.coverLetter);
      // console.log(formDataToUpload);
    }
    try {
      const response = await fetch(`${CMSURL}/candidates`, {
        method: "POST",
        body: formDataToUpload,
      });
      // const data = await response.json();
      // console.log(data);
      if (response.ok) {
        toast.success("Form submitted successfully");
        navigate("/");
      } else {
        setFormData((prev) => ({
          ...prev,
          sendingData: false,
        }));
      }
    } catch (error) {
      toast.error(`Error during authentication ${error.message}`);
      setFormData((prev) => ({
        ...prev,
        sendingData: false,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setFormData({
      ...formData,
      [name]: fieldValue,
    });
  };
  const handleFileChange = (e) => {
    const { name } = e.target;
    setFormData((prev) => ({ ...prev, [name]: e.target.files[0] }));
  };
  return (
    <ApplicationFormContext.Provider
      value={{
        formData,
        setFormData,
      }}
    >
      <main className="max-w-screen-lg mx-auto">
        <div className="flex items-center justify-center px-5 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12">
          <div className="w-full">
            <div className=" p-5 lg:p-8 border  mt-8 grid gap-6 bg-white rounded-lg">
              <form
                className="grid gap-5 "
                onSubmit={handleSubmit}
                encType="multipart-form"
              >
                <div className="grid gap-2 border-b pb-5">
                  <h1 className="text-3xl font-secondary">{formData?.title}</h1>
                  <h3 className="p-2 px-4 bg-neutral-200 text-xs uppercase font-bold text-neutral-600 rounded-full w-fit">
                    {formData?.company_name}
                  </h3>
                </div>
                <h2 className="text-2xl font-secondary">Application Form</h2>
                <input
                  type="date"
                  name="applicationDate"
                  value={formattedDate}
                  hidden
                />
                <TextInput
                  label={"Full Name"}
                  name="fullName"
                  value={formData.fullName}
                  errors={errors.fullName}
                  handleChange={handleChange}
                  isRequired={true}
                />

                <TextInput
                  label={"Email"}
                  name="email"
                  value={formData.email}
                  errors={errors.email}
                  handleChange={handleChange}
                  isRequired={true}
                />

                <NumberInput
                  label={"Contact Number"}
                  name="contactNumber"
                  value={formData.contactNumber}
                  errors={errors.contactNumber}
                  handleChange={handleChange}
                  isRequired={true}
                />
                <ReactTagInputComponent />

                <div className="w-full">
                  <label htmlFor={"DOB"} className="font-secondary">
                    {"Date Of Birth"}{" "}
                    <span className="text-red-500 text-2xl">*</span>
                  </label>
                  <input
                    type="date"
                    id={"DOB"}
                    name={"DOB"}
                    value={formData.DOB}
                    placeholder=""
                    onChange={handleChange}
                    className=" w-full  text-neutral-800 border border-neutral-800  placeholder-neutral-500 font-secondary p-3 rounded-md focus:outline-primary"
                  />
                  {errors.DOB && (
                    <div className="text-red-500">{errors.DOB}</div>
                  )}
                </div>

                <TextInput
                  label={"Current Company"}
                  name="currentCompany"
                  value={formData.currentCompany}
                  errors={errors.currentCompany}
                  handleChange={handleChange}
                  // isRequired={true}
                />

                <TextInput
                  label={"Current Position"}
                  name="currentPosition"
                  value={formData.currentPosition}
                  errors={errors.currentPosition}
                  handleChange={handleChange}
                  // isRequired={true}
                />

                <SelectInput
                  label={"Current Employment Status"}
                  name={"currentEmploymentStatus"}
                  value={formData.currentEmploymentStatus}
                  handleChange={handleChange}
                  error={errors.currentEmploymentStatus}
                  options={[
                    {
                      name: "Full Time",
                      value: "FullTime",
                    },
                    {
                      name: "Part Time",
                      value: "PartTime",
                    },
                    {
                      name: "Casual",
                      value: "Casual",
                    },
                    {
                      name: "Intern",
                      value: "Intern",
                    },
                    {
                      name: "None",
                      value: "None",
                    },
                  ]}
                />
                <div className="w-full">
                  <label htmlFor="resume" className="font-secondary">
                    Resume <span className="text-red-500 text-2xl">*</span>
                  </label>
                  <input
                    type="file"
                    id="resume"
                    name="resume"
                    placeholder=""
                    onChange={handleFileChange}
                    className=" w-full  text-slate-800 border border-slate-800  placeholder-slate-500 font-secondary p-3 rounded-md focus:outline-primary"
                  />
                  {errors.resume && (
                    <div className="text-red-500">{errors.resume}</div>
                  )}
                </div>

                <div className="w-full">
                  <label htmlFor="coverLetter" className="font-secondary">
                    Cover Letter{" "}
                    <span className="text-red-500 text-2xl">*</span>
                  </label>
                  <input
                    type="file"
                    id="coverLetter"
                    name="coverLetter"
                    placeholder=""
                    onChange={handleFileChange}
                    className=" w-full  text-slate-800 border border-slate-800  placeholder-slate-500 font-secondary p-3 rounded-md focus:outline-primary"
                  />
                  {errors.coverLetter && (
                    <div className="text-red-500">{errors.coverLetter}</div>
                  )}
                </div>

                <div className="sm:flex sm:items-center sm:gap-4">
                  <button
                    className="group w-fit px-10 py-4  bg-neutral-900 disabled:bg-neutral-600 text-slate-50 focus:outline-primary font-secondary text-sm font-bold uppercase tracking-widest rounded "
                    disabled={formData.sendingData}
                  >
                    {formData.sendingData ? (
                      <div className="flex items-center ">
                        <svg
                          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        {""}
                        <p>"Sending..."</p>
                      </div>
                    ) : (
                      <span className="flex items-center gap-2  pb-1 cursor-pointer bg-[length:0%_1px] bg-[0%_100%] bg-no-repeat bg-gradient-to-b from-black to-black group-hover:bg-[length:100%_1px] duration-300">
                        Apply <FiSend />
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </ApplicationFormContext.Provider>
  );
}

ApplicantForm.propTypes = {};

export default ApplicantForm;
