import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import ViewButton from "../components/Buttons/ViewButton";
// import DeleteButton from "../components/Buttons/DeleteButton";
import { FiLogOut } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { axiosClient } from "../components/axios/axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export default function Dashboard() {
  const navigate = useNavigate();
  const [candidateData, setcandidateData] = useState([]);
  const token = Cookies.get("token");
  console.log(candidateData);
  axiosClient.defaults.withCredentials = true;
  const vacancyData = async () => {
    try {
      let response = await axiosClient.get(`/candidate-applications`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setcandidateData(response.data);
      }
      if (response.status === 404) {
        toast.error("No candidate applications found");
      }
      if (response.status === 403) {
        toast.error("Please Login  to view your profile");
        navigate("/login");
      }
    } catch (error) {
      toast.error(error);
    }
  };
  useEffect(() => {
    vacancyData();
  }, []);

  const columns = [
    { Header: "SN", accessor: "sn" },
    { Header: "Title", accessor: "title" },
    { Header: "Post Date", accessor: "postedDate" },
    { Header: "Application Deadline", accessor: "application_valid_till" },
    { Header: "Applicants", accessor: "candidateCount" },
    { Header: "Actions", accessor: "action" },
  ];
  const applicantsData = candidateData.map((applicants, index) => {
    return {
      ...applicants,
      sn: index,
      candidateCount: applicants.candidate_applications.length,
      action: (
        <div className="flex gap-2">
          <ViewButton text={"View"} link={`/vacancies/${applicants.slug}`} />
          {/* <DeleteButton
        dispatchFunc={async () => {
          try {
            const response = await fetch(
              `${CMSURL}/api/products/${item.id}`,
              {
                method: "DELETE",

                headers: {
                  Authorization: `Bearer ${TOKEN}`,
                },
              }
            );
            if (response.ok) {
              getAllJewelry();
            }
          } catch (error) {
            // toast.error(`Error during authenticaiton ${error}`);
          }
        }}
      /> */}
        </div>
      ),
    };
  });

  return (
    <>
      <nav className="flex justify-end p-5 max-w-screen-xl">
        <Link to="/" className="flex items-center gap-2">
          <FiLogOut /> Logout
        </Link>
      </nav>
      <main className="max-w-screen-xl border border-neutral-200">
        <Table
          title={"Dashboard"}
          columnsData={columns}
          dataValue={applicantsData}
        />
      </main>
    </>
  );
}
