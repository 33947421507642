import React, { useContext, useEffect, useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import { ApplicationFormContext } from "../../context/ApplicationFormContext";

export default function ReactTagInputComponent() {
  const { setFormData } = useContext(ApplicationFormContext);
  const [tags, setTags] = useState([
    // { id: "Thailand", text: "Thailand" },
    // { id: "India", text: "India" },
    // { id: "Vietnam", text: "Vietnam" },
    // { id: "Turkey", text: "Turkey" },
  ]);
  // console.log("tags", tags);
  const [suggestions, setSuggestions] = useState([
    { id: "c++", text: "c++" },
    { id: "java", text: "java" },
    { id: "kotlin", text: "kotlin" },
    { id: "flutter", text: "flutter" },
  ]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      skills: tags,
    }));
  }, [tags]);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };
  const handleAddition = (tag) => {
    setTags((prevTags) => {
      return [...prevTags, tag];
    });
  };
  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };
  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };
  return (
    <div className="">
      <label className="font-secondary">
        Please Enter all the Skills you have for this role
        {/* <span className="text-red-500 text-2xl">*</span> */}
      </label>

      <ReactTags
        tags={tags}
        suggestions={suggestions}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        inputFieldPosition="inline"
        autocomplete
      />
    </div>
  );
}
