import React, { useEffect, useRef, useState } from "react";
import { FiArrowRight, FiMapPin } from "react-icons/fi";
import { Link } from "react-router-dom";
import { axiosClient } from "../components/axios/axios";
// import { FaCircleArrowRight } from "react-icons/fa6";

// ? Compnay logos
import idaLogo from "/Logos/ida-logo.svg";
import ideLogo from "/Logos/ide-logo.svg";
import onairLogo from "/Logos/on-air-logo.svg";
import aashaEvent from "/Logos/aasha-event-logo.svg";
import ilhLogo from "/Logos/ilh-logo.svg";
import plf from "/Logos/plf.svg";
// ? Compnay logos
// ? hero image
import heroSection from "/BackgroundImages/herosection.jpg";
// ? hero image

export default function HomePage() {
  const [data, setData] = useState([]);

  const effectRan = useRef(false);

  const getVacancies = async () => {
    try {
      let response = await axiosClient.get(`/vacancies`);
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!effectRan.current) {
      getVacancies();
    }
    return () => {
      effectRan.current = true;
    };
  }, []);

  return (
    <main className=" text-neutral-800  ">
      <section
        className="bg-center bg-cover"
        style={{ backgroundImage: `url(${heroSection})` }}
      >
        <div className="max-w-screen-xl mx-auto grid lg:grid-cols-[55%_1fr] gap-6 py-20 lg:py-32 px-5">
          <div className="space-y-4 order-2">
            <h1 className="text-center text-pretty md:text-start text-3xl md:text-4xl lg:text-5xl font-semibold  text-neutral-800 max-w-screen-md ">
              Explore Career Opportunities with Infinity Group Of Companies
            </h1>
            <p className="text-center text-pretty md:text-start lg:w-11/12 text-neutral-700 lg:text-lg max-w-screen-md">
              Welcome to the official job portal of Infinity Group Of Companies.
              Discover exciting career prospects tailored just for you. Here, we
              exclusively showcase job openings within our organization,
              offering unique roles and opportunities for growth. Join us on the
              journey towards success and fulfillment. Browse our vacancies and
              take the next step in your career today!
            </p>
          </div>

          <img
            src="/BackgroundImages/hris-cover.png"
            alt="Placeholder Image"
            className="rounded-md w-full h-auto order-1 md:order-2"
          />
        </div>
      </section>

      {/* Featured Jobs */}
      <section className="max-w-screen-xl mx-auto flex flex-col gap-8 py-10 md:py-20 px-5 border-b-2 border-b-neutral-200 ">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-medium text-center  text-neutral-800  ">
          Featured Career Opportunities
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 ">
          {data.map((vacancy, index) => (
            <article
              key={index}
              className="flex flex-col justify-between gap-6 cursor-pointer  border-2 border-neutral-500  p-5 duration-300 rounded-md"
            >
              <hgroup className="grid gap-1">
                <h2 className="text-xl font-semibold">
                  {vacancy.attributes.title}
                </h2>
                <h3 className="text-neutral-600 group-hover:text-neutral-400">
                  {vacancy.attributes.company_name}
                </h3>
                <p className=" flex gap-2 items-center text-neutral-700 group-hover:text-neutral-400">
                  <FiMapPin /> <span>{vacancy.attributes.location}</span>
                </p>
              </hgroup>

              <div className="flex gap-1 flex-wrap">
                <span className="bg-amber-200 group-hover:bg-amber-400 text-neutral-800 rounded p-1 px-2  group-hover:text-neutral-800 ">
                  {vacancy.attributes.job_type}
                </span>
                <span className="bg-amber-200 group-hover:bg-amber-400 text-neutral-800 rounded p-1 px-2  group-hover:text-neutral-800 ">
                  Valid till {vacancy.attributes.application_valid_till}
                </span>
                <span className="bg-amber-200 group-hover:bg-amber-400 text-neutral-800 rounded p-1 px-2  group-hover:text-neutral-800 ">
                  {vacancy.attributes.level}
                </span>
              </div>
              <div className="">{vacancy.attributes.excerpt}</div>
              <div className="flex flex-col md:flex-row items-center gap-4">
                <Link
                  className="flex items-center justify-center gap-2 bg-neutral-700 hover:bg-neutral-600 text-white text p-2 px-4  rounded  w-full md:w-fit duration-300"
                  to={`vacancies/${vacancy.attributes.slug}/application-form`}
                >
                  Apply now
                </Link>
                <Link
                  className="w-fit  text-center hover:bg-neutral-300 hover:text-black rounded px-4 py-2 duration-300"
                  to={`vacancies/${vacancy.attributes.slug}`}
                >
                  Learn More
                </Link>
              </div>
            </article>
          ))}
        </div>
        {/* <button className="flex items-center justify-center gap-2 border-2 border-neutral-600 p-4  rounded w-full md:w-fit ">
          View more <FiArrowRight />
        </button> */}
      </section>

      {/* Company Logo */}
      <section className="max-w-screen-xl mx-auto flex flex-col md:gap-8 py-10 md:py-20 px-5 border-b-2 border-b-neutral-200 ">
        <h2 className="text-2xl lg:text-4xl  font-medium text-center  text-neutral-800">
          Infinity Group of Companies
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 md:gap-2  ">
          <img
            src={idaLogo}
            alt="Infinity Digital Agency Website"
            // className="md:border-b border-neutral-500 "
            className=""
          />
          <img
            src={ideLogo}
            alt="Infinity Development Enterprise Website"
            // className="md:border-b border-neutral-500 lg:border-x "
            className=""
          />

          <img src={onairLogo} alt="On Air Solutions Website" className="" />

          <img src={ilhLogo} alt="Infinity Learning Hub Website" className="" />

          <img src={aashaEvent} alt="Aasha Event Website" className="" />
          <div className="flex justify-center items-center ">
            <img className="h-36 lg:h-24" src={plf} alt="Aasha Event Website" />
          </div>
        </div>
      </section>
    </main>
  );
}
