import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  FiCalendar,
  FiCreditCard,
  FiFile,
  FiMail,
  FiPhone,
  FiSave,
  FiSend,
  FiUser,
} from "react-icons/fi";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";

import { axiosClient } from "../components/axios/axios";
import { SelectInput, TextInput } from "../components/Inputs/Inputs";
import handleChange from "../utils/handleChange";
import handleFileChange from "../utils/handleFileChange";
import "react-quill/dist/quill.snow.css";
import { HiIdentification } from "react-icons/hi";

export default function CandidateApplication() {
  let CMSURL = import.meta.env.VITE_CMS_URL;
  const param = useParams();
  const token = Cookies.get("token");
  const [applicant, setApplicent] = useState([]);
  const [formData, setFormData] = useState();
  console.log(formData);
  const handleQuillChange = (name) => (value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToUpload = new FormData();
    const data = {};
    for (const key in formData) {
      if (key !== "contract") {
        data[key] = formData[key];
      }
    }
    formDataToUpload.append("data", JSON.stringify(data));
    if (formData?.contract) {
      formDataToUpload.append("files.contract", formData?.contract);
    }
    try {
      const response = await fetch(
        `${CMSURL}/candidate-applications/${param.slug}`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${token}` },
          body: formDataToUpload,
        }
      );
      if (response.ok) {
        toast.success("Application Updated Successfully");
      }
    } catch (error) {
      toast.error(`Error Updating Application ${error} `);
      console.log(error);
    }
  };

  const handleRejectionEmailSend = async (e) => {
    e.preventDefault();
    console.log("This is rejection Email", formData.rejectionEmail);
    // todo - Impliment send rejection email feature.

    // const formDataToUpload = new FormData();
    // const data = {};
    // for (const key in formData) {
    //   if (key !== "contract") {
    //     data[key] = formData[key];
    //   }
    // }
    // formDataToUpload.append("data", JSON.stringify(data));
    // if (formData?.contract) {
    //   formDataToUpload.append("files.contract", formData?.contract);
    // }
    // try {
    //   const response = await fetch(
    //     `${CMSURL}/api/candidate-applications/${param.slug}`,
    //     {
    //       method: "PUT",
    //       headers: { Authorization: `Bearer ${token}` },
    //       body: formDataToUpload,
    //     }
    //   );
    //   if (response.ok) {
    //     toast.success("Application Updated Successfully");
    //   }
    // } catch (error) {
    //   toast.error(`Error Updating Application ${error} `);
    //   console.log(error);
    // }
  };

  const getThisApplicationFromVacency = async () => {
    try {
      let response = await axiosClient.get(
        `/candidate-applications/${param.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        const allApplicents = response.data.candidate_applications;
        const candidate = allApplicents.find(
          (item) => Number(item.id) === Number(param.slug)
        );
        console.log("Candidate is ", candidate);
        setApplicent(candidate);
        setFormData((prev) => ({ ...prev, ...candidate }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getThisApplicationFromVacency();
  }, []);

  const APPLICATION_STATUS_OPTIONS = [
    {
      name: "Pending",
      value: "pending",
    },
    {
      name: "Accepted",
      value: "Accepted",
    },
    {
      name: "Rejected",
      value: "rejected",
    },
  ];
  // DATE SET/ TAKEN/ PENDING/REJECTED
  const PHONE_INTERVIEW_STATUS = [
    {
      name: "Pending",
      value: "Pending",
    },
    {
      name: "Date Set",
      value: "DateSet",
    },
    {
      name: "Taken",
      value: "Taken",
    },
  ];
  let userDetails = [];

  return (
    <main className="flex flex-col gap-5 p-5">
      <div className="flex gap-4 items-center pb-5 border-b">
        <div className="">
          <div className="flex gap-2 items-baseline ">
            <h1 className="font-secondary font-bold text-slate-900 text-4xl capitalize  border-r-2 pr-2 ">
              {applicant.candidateId?.fullName}
            </h1>
          </div>

          <p>{applicant.candidateId?.email}</p>
        </div>
      </div>
      {/* //! Candidate detail */}
      <div className="grid lg:grid-cols-[210px_1fr] gap-10 border rounded p-5 max-w-screen-xl">
        <h1 className="text-2xl font-semibold w-fit text-nowrap">
          Candidate Detail
        </h1>
        <div className="flex flex-col gap-5">
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <FiUser /> <p className="text-base font-medium">Name :</p>
            </div>
            <p>{applicant?.candidateId?.fullName}</p>
          </div>

          <div className="grid lg:grid-cols-[150px_1fr] gap-2  ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <FiMail /> <p className="text-base font-medium">Email :</p>
            </div>
            <p>{applicant?.candidateId?.email}</p>
          </div>
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <FiCalendar />{" "}
              <p className="text-base font-medium">Date Of Birth :</p>
            </div>
            <p>{applicant?.candidateId?.DOB}</p>
          </div>
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <FiCreditCard />{" "}
              <p className="text-base font-medium">Citizenship No :</p>
            </div>
            <p>{applicant?.candidateId?.citizenship_number}</p>
          </div>
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <FiPhone /> <p className="text-base font-medium">Contact No :</p>
            </div>
            <p>{applicant?.candidateId?.contactNumber}</p>
          </div>
        </div>
      </div>
      {/* //? Candidate detail */}
      {/* //! Application detail */}
      <div className="grid lg:grid-cols-[210px_1fr] gap-10 border rounded p-5 max-w-screen-xl">
        <h1 className=" text-2xl font-semibold w-fit text-nowrap">
          Application Detail
        </h1>
        <div className="flex flex-col gap-5 w-full">
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <FiMail />{" "}
              <p className="text-base font-medium">Application Date :</p>
            </div>
            <p>{applicant?.applicationDate}</p>
          </div>
          {/* cover letter */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <FiFile /> <p className="text-base font-medium">Cover Letter :</p>
            </div>
            <Link
              className="underline hover:text-amber-500 duration-300"
              to={`${CMSURL}${applicant.coverLetter?.url}`}
            >
              {applicant.coverLetter?.name}
            </Link>
          </div>
          {/* Resume */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <FiFile /> <p className="text-base font-medium">Resume :</p>
            </div>
            <Link
              className="underline hover:text-amber-500 duration-300"
              to={`${CMSURL}${applicant.resume?.url}`}
            >
              {applicant.coverLetter?.name}
            </Link>
          </div>
          {/* Application Status */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <p className="text-base text-nowrap font-medium w-fit">
                Applicaiton status :
              </p>
            </div>
            <div className="w-full max-w-screen-sm">
              <SelectInput
                name={"currentApplicationStatus"}
                value={formData?.currentApplicationStatus}
                handleChange={handleChange(setFormData)}
                options={APPLICATION_STATUS_OPTIONS}
              />
            </div>
          </div>
          {/* Application Reason */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-start gap-1 text-base text-neutral-700 ">
              <p className="text-base text-nowrap font-medium w-fit">
                Applicaiton Reason :
              </p>
            </div>
            <div className="w-full max-w-screen-sm">
              <ReactQuill
                theme="snow"
                value={formData?.currentApplicationStatusReason}
                onChange={handleQuillChange("currentApplicationStatusReason")}
              />
            </div>
          </div>
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-start gap-1 text-base text-neutral-700 ">
              <p className="font-medium">
                Hiring/Moving Forward/Rejection Reason
              </p>
            </div>
            <div className="w-full max-w-screen-sm">
              <ReactQuill
                theme="snow"
                value={formData?.hiring_movingForward_rejectionStatusReason}
                onChange={handleQuillChange(
                  "hiring_moveForward_rejectionStatusReason"
                )}
              />
            </div>
          </div>
        </div>
      </div>
      {/* //? Application Detail */}
      {/* //! Phone Interview Phase */}
      <div className="grid lg:grid-cols-[210px_1fr] gap-10 border rounded p-5 max-w-screen-xl">
        <h1 className=" text-2xl font-semibold w-fit text-nowrap">
          Phone Interview
        </h1>
        <div className="flex flex-col gap-5 w-full">
          {/* Phone INterview Status */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <p className="text-base text-nowrap font-medium w-fit">
                Interview status :
              </p>
            </div>
            <div className="w-full max-w-screen-sm">
              <SelectInput
                name={"phoneInterviewStatus"}
                value={formData?.phoneInterviewStatus}
                handleChange={handleChange(setFormData)}
                options={PHONE_INTERVIEW_STATUS}
              />
            </div>
          </div>
          {/* Phone Intervew Date */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <p className="text-base text-nowrap font-medium w-fit">
                Interview Date :
              </p>
            </div>
            <div className="w-full max-w-screen-sm">
              <input
                name="phoneInterviewDateTime"
                className="border w-full p-3 rounded "
                type="date"
                value={formData?.phoneInterviewDateTime}
                onChange={handleChange(setFormData)}
              />
            </div>
          </div>
          {/* Phone Interview Taken By */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <p className="">Interview Was taken By :</p>
            </div>
            <div className="w-full max-w-screen-sm">
              <TextInput
                name={"phoneInterviewBy"}
                value={formData?.phoneInterviewBy}
                handleChange={handleChange(setFormData)}
              />
            </div>
          </div>
          {/* has passed Phone interview */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center  gap-1 text-base text-neutral-700 ">
              <p className="font-medium">Has Passed Phone Interview :</p>
            </div>
            <div className="w-full max-w-screen-sm">
              <div className="flex">
                <label
                  htmlFor="hasPassedPhoneInterview"
                  className="relative w-14  h-8 p-2 cursor-pointer rounded-full bg-neutral-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-green-500"
                >
                  <input
                    name="hasPassedPhoneInterview"
                    type="checkbox"
                    id="hasPassedPhoneInterview"
                    className="peer sr-only"
                    value={formData?.hasPassedPhoneInterview}
                    onChange={handleChange(setFormData)}
                    checked={formData?.hasPassedPhoneInterview}
                  />

                  <span className="absolute inset-y-0 start-0 m-1 size-6 rounded-full bg-white transition-all peer-checked:start-6"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //? Phone Interview Phase */}
      {/* //! First Phase  */}
      {/* {formData?.hasPassedPhoneInterview && ( */}
      <div className="grid lg:grid-cols-[210px_1fr] gap-10 border rounded p-5 max-w-screen-xl">
        <h1 className=" text-2xl font-semibold w-fit text-nowrap">
          First Phase
        </h1>
        <div className="flex flex-col gap-5 w-full">
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <p className="text-base text-nowrap font-medium w-fit">
                Interview status :
              </p>
            </div>
            <div className="w-full max-w-screen-sm">
              <SelectInput
                name={"firstInterviewStatus"}
                value={formData?.firstInterviewStatus}
                handleChange={handleChange(setFormData)}
                options={PHONE_INTERVIEW_STATUS}
              />
            </div>
          </div>
          {/* First Phase Interview Date */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center  gap-1 text-base text-neutral-700 ">
              <p className="text-base text-nowrap font-medium w-fit">
                Interview Date :
              </p>
            </div>
            <div className="w-full max-w-screen-sm">
              <input
                className="border w-full p-3 rounded "
                type="date"
                name="FPII_date_time"
                value={formData?.FPII_date_time}
                onChange={handleChange(setFormData)}
              />
            </div>
          </div>
          {/* First Phase Interview was taken */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <p className="">Interview Was taken By :</p>
            </div>
            <div className="w-full max-w-screen-sm">
              <TextInput
                name={"phoneInterviewBy"}
                value={formData?.phoneInterviewBy}
                handleChange={handleChange(setFormData)}
              />
            </div>
          </div>
          {/* Has Passed First Phase Interview */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center  gap-1 text-base text-neutral-700 ">
              <p className="font-medium">Has Passed First Phase :</p>
            </div>
            <div className="w-full max-w-screen-sm">
              <div className="flex">
                <label
                  htmlFor="hasPassedFirstPhaseInterview"
                  className="relative w-14  h-8 p-2 cursor-pointer rounded-full bg-neutral-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-green-500"
                >
                  <input
                    name="hasPassedFirstPhaseInterview"
                    type="checkbox"
                    id="hasPassedFirstPhaseInterview"
                    className="peer sr-only"
                    value={formData?.hasPassedFirstPhaseInterview}
                    onChange={handleChange(setFormData)}
                    checked={formData?.hasPassedFirstPhaseInterview}
                  />

                  <span className="absolute inset-y-0 start-0 m-1 size-6 rounded-full bg-white transition-all peer-checked:start-6"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
      {/* //? First Phase */}

      {/* //! second phase  Interview */}
      {/* //todo the line below */}
      {/* {formData?.hasPassedFirstPhaseInterview && ( */}
      <div className="grid lg:grid-cols-[210px_1fr] gap-10 border rounded p-5 max-w-screen-xl">
        <h1 className=" text-2xl font-semibold w-fit text-nowrap">
          Second Phase
        </h1>
        <div className="flex flex-col gap-5 w-full">
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <p className="text-base text-nowrap font-medium w-fit">
                Interview status :
              </p>
            </div>
            <div className="w-full max-w-screen-sm">
              <SelectInput
                name={"secondInterviewStatus"}
                value={formData?.secondInterviewStatus}
                handleChange={handleChange(setFormData)}
                options={PHONE_INTERVIEW_STATUS}
              />
            </div>
          </div>
          {/* Second Phase Interview Date */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <p className="font-medium">Interview Date :</p>
            </div>
            <div className="w-full max-w-screen-sm">
              <input
                className="border w-full p-3 rounded "
                type="date"
                name="SPII_date_time"
                value={formData?.SPII_date_time}
                onChange={handleChange(setFormData)}
              />
            </div>
          </div>
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <p className="">Interview Was taken By :</p>
            </div>
            <div className="w-full max-w-screen-sm">
              <TextInput
                name={"phoneInterviewBy"}
                value={formData?.phoneInterviewBy}
                handleChange={handleChange(setFormData)}
              />
            </div>
          </div>
          {/* Has Passed First Phase Interview */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center justify-center gap-1 text-base text-neutral-700 ">
              <p className=" font-medium ">Has Passed Second Phase :</p>
            </div>
            <div className="flex">
              <label
                htmlFor="hasPassedSecondPhaseInterview"
                className="relative w-14  h-8 p-2 cursor-pointer rounded-full bg-neutral-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-green-500"
              >
                <input
                  name="hasPassedSecondPhaseInterview"
                  type="checkbox"
                  id="hasPassedSecondPhaseInterview"
                  className="peer sr-only"
                  value={formData?.hasPassedSecondPhaseInterview}
                  onChange={handleChange(setFormData)}
                  checked={formData?.hasPassedSecondPhaseInterview}
                />

                <span className="absolute inset-y-0 start-0 m-1 size-6 rounded-full bg-white transition-all peer-checked:start-6"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
      {/* //todo the line above */}
      {/* 
      // * Add a logic 
      // todo - Show the accept and rejected section according to application status
      */}
      {/* //! Accept */}
      <div className="grid lg:grid-cols-[210px_1fr] gap-10 border rounded p-5 max-w-screen-xl">
        <h1 className=" text-2xl font-semibold w-fit text-nowrap">Accepted</h1>
        <div className="flex flex-col gap-5 w-full">
          {/* Provision */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <p className="font-medium"> Provasion Period :</p>
            </div>
            <div className="w-full max-w-screen-sm">
              <input
                name="probation-period"
                className="border w-full p-3 rounded "
                type="date"
                value={formData?.probation_period}
                onChange={handleChange(setFormData)}
              />
            </div>
          </div>
          {/* contract */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <p className="font-medium">Contract :</p>
            </div>
            <div className="w-full max-w-screen-sm">
              <input
                type="file"
                id="contract"
                name="contract"
                onChange={handleFileChange(setFormData)}
                className=" w-full  text-slate-800 border border-slate-800  placeholder-slate-500 font-secondary p-3 rounded-md focus:outline-primary"
              />
            </div>
          </div>

          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex  gap-1 text-base text-neutral-700 ">
              <p className="font-medium">Accepted Email: </p>
            </div>
            <div className="w-full max-w-screen-sm">
              <div className=" w-full flex flex-col gap-5">
                <ReactQuill
                  theme="snow"
                  value={formData?.rejectionEmail}
                  onChange={handleQuillChange("rejectionEmail")}
                />

                <button
                  onClick={handleRejectionEmailSend}
                  className="group w-fit px-6 py-3 bg-neutral-700 border  text-neutral-50 focus:outline-primary font-secondary text-sm font-bold uppercase tracking-widest rounded "
                >
                  <span className="flex items-center gap-2  pb-1 cursor-pointer bg-[length:0%_1px] bg-[0%_100%] bg-no-repeat bg-gradient-to-b from-black to-black group-hover:bg-[length:100%_1px] duration-300">
                    Send <FiSend />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-[210px_1fr] gap-10 border rounded p-5 max-w-screen-xl">
        <h1 className=" text-2xl font-semibold w-fit text-nowrap">Rejected</h1>
        <div className="flex flex-col gap-5 w-full">
          {/* Rejection Email Send ? */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex items-center gap-1 text-base text-neutral-700 ">
              <p className="font-medium">Rejection Email Send: </p>
            </div>
            <div className="w-full max-w-screen-sm">
              <div className="flex">
                <label
                  htmlFor="rejection_email_status"
                  className="relative w-14  h-8 p-2 cursor-pointer rounded-full bg-neutral-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-green-500"
                >
                  <input
                    name="rejection_email_status"
                    type="checkbox"
                    id="rejection_email_status"
                    className="peer sr-only"
                    value={formData?.rejection_email_status}
                    onChange={handleChange(setFormData)}
                    checked={formData?.rejection_email_status}
                  />

                  <span className="absolute inset-y-0 start-0 m-1 size-6 rounded-full bg-white transition-all peer-checked:start-6"></span>
                </label>
              </div>
            </div>
          </div>
          {/* Rejection Email  */}
          <div className="grid lg:grid-cols-[150px_1fr] gap-2 ">
            <div className="flex  gap-1 text-base text-neutral-700 ">
              <p className="font-medium">Rejection Email: </p>
            </div>
            <div className="w-full max-w-screen-sm">
              <div className=" w-full flex flex-col gap-5">
                <ReactQuill
                  theme="snow"
                  value={formData?.rejectionEmail}
                  onChange={handleQuillChange("rejectionEmail")}
                />

                <button
                  onClick={handleRejectionEmailSend}
                  className="group w-fit px-6 py-3 bg-neutral-700 border  text-neutral-50 focus:outline-primary font-secondary text-sm font-bold uppercase tracking-widest rounded "
                >
                  <span className="flex items-center gap-2  pb-1 cursor-pointer bg-[length:0%_1px] bg-[0%_100%] bg-no-repeat bg-gradient-to-b from-black to-black group-hover:bg-[length:100%_1px] duration-300">
                    Send <FiSend />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" grid grid-cols-[300px_1fr] ">
        {userDetails.map((item) => (
          <>
            <h2 className="border-b border-r p-5" key={item.name}>
              {item.name}
            </h2>
            <div className="border-b" key={item.data}>
              <div className=" p-5 max-w-screen-sm">
                {item.data ? (
                  item.data
                ) : (
                  <span className="text-slate-500 font-bold text-sm">N/A</span>
                )}
              </div>
            </div>
          </>
        ))}
      </div>
      <div className="sm:flex sm:items-center sm:gap-4 pt-5">
        <button
          onClick={handleSubmit}
          className="group w-fit px-10 py-4  bg-slate-900 border border-slate-950 text-slate-50 focus:outline-primary font-secondary text-sm font-bold uppercase tracking-widest rounded "
        >
          <span className="flex items-center gap-2  pb-1 cursor-pointer bg-[length:0%_1px] bg-[0%_100%] bg-no-repeat bg-gradient-to-b from-black to-black group-hover:bg-[length:100%_1px] duration-300">
            Send <FiSave />
          </span>
        </button>
      </div>
    </main>
  );
}
