import PropTypes from "prop-types";
import placeholder from "../../assets/placeholder-image.png";

export function ImageInput({ label, name, imgSrc, error, handleImageUpload }) {
  ImageInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    imgSrc: PropTypes.object,
    error: PropTypes.object,
    handleImageUpload: PropTypes.func,
  };

  return (
    <div className="w-full">
      <label
        htmlFor={name}
        className="space-y-2 font-secondary cursor-pointer"
        onDragOver={handleImageUpload}
        onDrop={handleImageUpload}
      >
        {label}

        <div className="border border-neutral-900 bg-neutral-50 rounded-md">
          <input
            type="file"
            id={name}
            name={name}
            placeholder="Drag and drop an image"
            onChange={handleImageUpload}
            className="w-full file:mr-3 file:p-3 file:border-y-0 file:border-l-0 file:border-r-neutral-100 file:border-0  file:font-secondary file:bg-transparent file:text-neutral-800 hover:file:cursor-pointer border-b border-b-neutral-100 focus:outline-primary text-neutral-950"
            accept=".jpg,.png,.webp"
          />
          <img
            className="size-40 w-full object-contain "
            src={imgSrc?.url ? imgSrc?.url : imgSrc ? imgSrc : placeholder}
          />
        </div>
      </label>

      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
}

export function TextInput({
  name,
  label,
  value,
  handleChange,
  error,
  isRequired,
}) {
  TextInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    handleChange: PropTypes.func,
    error: PropTypes.object,
    isRequired: PropTypes.bool,
  };
  return (
    <div className="w-full justify-stretch">
      <label htmlFor={name} className="font-secondary">
        {label} {isRequired && <span className="text-red-500 text-2xl">*</span>}
      </label>
      <input
        type="text"
        id={name}
        name={name}
        value={value}
        placeholder=""
        onChange={handleChange}
        className=" w-full  text-neutral-800 border border-neutral-800  placeholder-neutral-500 font-secondary p-3 rounded-md focus:outline-primary"
      />
      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
}

export function NumberInput({
  name,
  label,
  value,
  handleChange,
  error,
  isRequired,
}) {
  NumberInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    handleChange: PropTypes.func,
    error: PropTypes.object,
    isRequired: PropTypes.bool,
  };
  return (
    <div className="w-full">
      <label htmlFor={name} className="font-secondary">
        {label} {isRequired && <span className="text-red-500 text-2xl">*</span>}
      </label>
      <input
        type="number"
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        className="appearance-none  w-full  text-neutral-800 border border-neutral-800 placeholder-neutral-500 font-secondary p-3 rounded-md focus:outline-primary"
      />
      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
}

export function SelectInput({
  name,
  label,
  value,
  options,
  handleChange,
  error,
  isRequired,
}) {
  SelectInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    handleChange: PropTypes.func,
    error: PropTypes.object,
    options: PropTypes.array,
    isRequired: PropTypes.bool,
  };

  return (
    <div className=" flex flex-col gap-2 ">
      <label htmlFor={name} className="font-secondary">
        {label}{" "}
        {isRequired ? <span className="text-red-500 text-2xl">*</span> : ""}
      </label>
      <select
        name={name}
        value={value ? value : "---Select A category ---"}
        id={name}
        className="w-full bg-white text-neutral-800 border placeholder-neutral-500 font-secondary p-3 rounded"
        onChange={handleChange}
      >
        {options?.map((item, index) => (
          <option className="py-4 border-b" value={item.value} key={index}>
            {item.name}
          </option>
        ))}
      </select>
      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
}

export function TextArea({
  name,
  label,
  value,
  handleChange,
  error,
  isRequired,
}) {
  TextArea.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    handleChange: PropTypes.func,
    error: PropTypes.object,
    options: PropTypes.array,
    isRequired: PropTypes.bool,
  };

  return (
    <div className=" flex flex-col items-stretch gap-2 ">
      <label htmlFor={name} className=" font-secondary">
        {label}{" "}
        {isRequired ? <span className="text-red-500 text-2xl ">*</span> : ""}
      </label>
      <textarea
        name={name}
        id={name}
        value={value}
        className="w-full self-stretch text-neutral-800 border   placeholder-neutral-500 font-secondary  p-3  bg-white rounded"
        onChange={handleChange}
      />
      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
}
